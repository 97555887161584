import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";
import ContactUs from "@/components/contact-us/ContactUs";

const DigitalTwinPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO title={t('digitalTwin')} />
      <Layout>
        <Banner
          title={t('digitalTwin')}
          bgColor="#F2EDE8"
          textColor="rgb(41, 55, 102)"
          img={'/images/researches/Industrial_Engineers_Analyzing_Control_Data.jpg'}
        />

        <div className="flex flex-col page-content pt-16 pb-20">
          <div className="flex flex-col md:flex-1 max-w-7xl mx-auto">
            <div className="flex flex-col w-full">
              <h2 className="text-4xl font-bold mb-8 text-center" style={{color:"rgb(41, 55, 102)"}}>{t('researchIntro')}</h2>
              <p className="text-lgleading-relaxed text-gray-700 mb-12  text-lg">
                {t('digitalTwinDesc')}
              </p>
            </div>

            <div className="flex flex-col w-full space-y-24">
            <h2 className="text-3xl font-bold text-center" style={{color:"rgb(41, 55, 102)",marginBottom:"-1rem"}}>{t('whyDigitalTwin')}</h2>
              <div className="grid-cols-1 md:grid-cols-3 gap-8" style={{display:"grid"}}>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-6">
                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4" style={{flexShrink:"0"}}>
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <h3 
                      className="text-2xl font-semibold"
                      style={{ color: "rgb(41, 55, 102)" }}
                    >
                      {t('digitalTwinFeature1Title')}
                    </h3>
                  </div>
                  <p 
                    className="text-lg leading-relaxed"
                    style={{ color: "rgb(41, 55, 102)" }}
                  >
                    {t('digitalTwinFeature1Desc')}
                  </p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-6">
                    <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mr-4"  style={{flexShrink:"0"}}>
                      <svg 
                        className="w-6 h-6 text-green-600" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                        role="img"
                        aria-label={t('digitalTwinFeature2Title')}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                    </div>
                    <h3 className="text-2xl font-semibold text-gray-900">{t('digitalTwinFeature2Title')}</h3>
                  </div>
                  <p className="text-lg leading-relaxed text-gray-700">
                    {t('digitalTwinFeature2Desc')}
                  </p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-6">
                    <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mr-4"  style={{flexShrink:"0"}}>
                      <svg 
                        className="w-6 h-6 text-purple-600" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                        role="img"
                        aria-label={t('digitalTwinFeature3Title')}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                    </div>
                    <h3 className="text-2xl font-semibold text-gray-900">{t('digitalTwinFeature3Title')}</h3>
                  </div>
                  <p className="text-lg leading-relaxed text-gray-700">
                    {t('digitalTwinFeature3Desc')}
                  </p>
                </div>
              </div>

              <div className="bg-gray-50 p-8 rounded-xl">
                <h3 
                  className="text-3xl font-semibold mb-8 text-center"
                  style={{ color: "rgb(41, 55, 102)" }}
                >
                  {t('digitalTwinImplementationTitle')}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">1</span>
                    </div>
                    <div>
                      <h4 
                        className="text-lg font-semibold mb-2"
                        style={{ color: "rgb(41, 55, 102)" }}
                      >
                        {t('digitalTwinStep1Title')}
                      </h4>
                      <p style={{ color: "rgb(41, 55, 102)" }} className="text-lg">
                        {t('digitalTwinStep1Desc')}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">2</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2">{t('digitalTwinStep2Title')}</h4>
                      <p className="text-gray-700 text-lg">{t('digitalTwinStep2Desc')}</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">3</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2">{t('digitalTwinStep3Title')}</h4>
                      <p className="text-gray-700 text-lg">{t('digitalTwinStep3Desc')}</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">4</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2">{t('digitalTwinStep4Title')}</h4>
                      <p className="text-gray-700 text-lg">{t('digitalTwinStep4Desc')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUs />
      </Layout>
    </>
  );
};

export default DigitalTwinPage; 