import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import ContactUs from "@/components/contact-us/ContactUs";
import Banner from "@/components/banner/Banner";

const OurFleetPage = () => {
    const { t } = useTranslation();
    const backgroundStyle = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%'
    };
    return (
        <Layout>
            <SEO title={t('ourFleet')} />
            <Banner
                title={t('ourFleet')}
                bgColor="#F2EDE8"
                textColor="rgb(41, 55, 102)"
                img={`../images/DDDI Group/DSC06977.jpg`}
            />

            <div className="pt-20 page-content" style={{ width: "100%" }}>
                {/* First Row */}
                <div className="pb-20">
                    <div className="mb-8 ">
                        <div className="leading-relaxed p-6" style={{ color: "rgb(41, 55, 102)", fontWeight: 500, fontFamily: "'Open Sans', sans-serif", fontSize: "1.25rem" }}>
                            {t('fleetDescription1')}
                        </div>
                    </div>
                    <div className="w-full">
                        <video
                            src="../images/DDDI Group/DDDI Group Tesla 最新.mov"
                            className="w-full object-cover"
                            autoPlay
                            muted
                            playsInline
                            loop
                        />
                    </div>
                </div>

                {/* Second Row */}
                <div className="flex flex-col-reverse md:flex-row pb-20">
                    <div className="w-full md:w-1/2 flex items-center justify-center p-8">
                        <div className="leading-relaxed" style={{ color: "rgb(41, 55, 102)", fontWeight: 500, fontFamily: "'Open Sans', sans-serif", fontSize: "1.25rem" }}>
                            {t('fleetDescription2')}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <img 
                            src="../images/DDDI Group/DSC07158.jpg"
                            className="w-full h-full object-cover"
                            style={{minHeight: "400px"}}
                            alt="Tesla vehicle"
                        />
                    </div>
                </div>

                {/* Third Row */}
                <div className="flex flex-col md:flex-row pb-20">
                    <div className="w-full md:w-1/2">
                        <img 
                            src="../images/DDDI Group/DSC06999.jpg"
                            className="w-full h-full object-cover"
                            style={{minHeight: "400px"}}
                            alt="Tesla vehicle"
                        />
                    </div>
                    <div className="w-full md:w-1/2 flex items-center justify-center p-8">
                        <div className="leading-relaxed" style={{ color: "rgb(41, 55, 102)", fontWeight: 500, fontFamily: "'Open Sans', sans-serif", fontSize: "1.25rem" }}>
                            {t('fleetDescription3')}
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs />
        </Layout>
    );
};

export default OurFleetPage; 