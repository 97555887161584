import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";
import projectJson from "../projectPage.json";
import ContactUs from "@/components/contact-us/ContactUs";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
// Import required modules
import { EffectCoverflow, Navigation } from 'swiper/modules';
import './DetailPage.css';

const DetailPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const project = projectJson.find(item => item.id === id);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <Layout>
      <SEO title={project.title} />
      <Banner
        title={project.title}
        bgColor="#F2EDE8"
        textColor="rgb(41, 55, 102)"
        img={project.imgUrl[0]}
      />

      <div className="page-content py-16">
        <div className="max-w-4xl mx-auto">
          <div className="prose max-w-none">
            <p style={{
              fontFamily: "'Open Sans', sans-serif",
              fontSize: "1.125rem",
              color: "rgb(41, 55, 102)"
            }}>
              {t(project.desc)}
            </p>
          </div>
        </div>
      </div>
      <div className="relative pb-3">
          {project.imgUrl.length > 1 && (
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1.6}
              spaceBetween={80}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              modules={[EffectCoverflow, Navigation]}
              className="mySwiper h-full"
            >
              {project.imgUrl.slice(1).map((image, index) => (
                <SwiperSlide key={index} className="swiper-slide">
                  <div className="relative h-[400px] transition-all duration-300">
                    <img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className="navigation-buttons">
            <button className="swiper-button-prev" />
            <button className="swiper-button-next" />
          </div>
        </div>
      <ContactUs />
    </Layout>
  );
};

export default DetailPage;
