import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import { useTranslation } from "react-i18next";
import Banner from "@/components/banner/Banner";

const OurPartners = () => {
    const titleStyle = {
        color: "rgb(41, 55, 102)",
        fontSize: "4rem",
        paddingBottom: "1.5rem",
        textAlign: "center",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500
    }
    const fontStyle = {
        color: "#fff"
    }
    const list1 = [
        {
            img: '../images/partner logo/federal government/logo_disr.png',
            url: 'https://www.industry.gov.au'
        },
        {
            img: '../images/partner logo/federal government/02.png', 
            url: 'https://www.abs.gov.au'
        },
        {
            img: '../images/partner logo/SA housing Authority/4.png',
            url: 'https://www.sa.gov.au'
        },
        {
            img: '../images/partner logo/SA housing Authority/1.png',
            url: 'https://www.homestart.com.au'
        },
        {
            img: '../images/partner logo/SA housing Authority/2.png',
            url: 'https://www.revenuesa.sa.gov.au'
        },
        {
            img: '../images/partner logo/SA housing Authority/3.png',
            url: 'https://www.housing.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/01.png',
            url: 'https://www.cityofadelaide.com.au'
        },
        {
            img: '../images/partner logo/city council/02.png',
            url: 'https://www.burnside.sa.gov.au/Home'
        },
        {
            img: '../images/partner logo/city council/03.png',
            url: 'https://www.campbelltown.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/04.png',
            url: 'https://www.gawler.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/05.png',
            url: 'https://www.holdfast.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/06.png',
            url: 'https://www.marion.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/07.png',
            url: 'https://www.npsp.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/08.png',
            url: 'https://www.onkaparingacity.com/Home'
        },
        {
            img: '../images/partner logo/city council/09.png',
            url: 'https://www.playford.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/10.png',
            url: 'https://www.cityofpae.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/11.png',
            url: 'https://www.prospect.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/12.png',
            url: 'https://www.salisbury.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/13.png',
            url: 'https://www.teatreegully.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/14.png',
            url: 'https://www.unley.sa.gov.au/Home'
        },
        {
            img: '../images/partner logo/city council/15.png',
            url: 'https://www.westtorrens.sa.gov.au/Home'
        },
        {
            img: '../images/partner logo/city council/16.png',
            url: 'https://www.charlessturt.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/17.png',
            url: 'https://www.mitchamcouncil.sa.gov.au'
        },
        {
            img: '../images/partner logo/city council/18.png',
            url: 'https://www.walkerville.sa.gov.au'
        }
    ]
    const list2 = [
        '../images/partner logo/SA housing Authority/4.png',
        '../images/partner logo/SA housing Authority/1.png',
        '../images/partner logo/SA housing Authority/2.png',
        '../images/partner logo/SA housing Authority/3.png',
    ]
    const list3 = [
        '../images/partner logo/Real Estate Agents/1.png',
        '../images/partner logo/Real Estate Agents/2.png',
        '../images/partner logo/Real Estate Agents/4.png',
        '../images/partner logo/Real Estate Agents/3.png',
        '../images/partner logo/Real Estate Agents/7.png',

        '../images/partner logo/Real Estate Agents/5.png',
        '../images/partner logo/Real Estate Agents/6.png',
    ]

    const list4 = [
        {
            img: '../images/partner logo/Data Support/02.png',
            url: 'https://www.corelogic.com.au'
        },
        {
            img: '../images/partner logo/Data Support/01.png',
            url: 'https://quaintquant.com'
        }
    ]

    const list5 = [
        {
            img: '../images/partner logo/legal support/2.png',
            url: 'https://lynchmeyer.com.au'
        },
        {
            img: '../images/partner logo/legal support/3.png',
            url: 'https://yapplawyers.com.au'
        },
        {
            img: '../images/partner logo/legal support/4.png',
            url: 'https://www.taoanlaw.com'
        },
        {
            img: '../images/partner logo/legal support/1.png',
            url: ''
        }
    ]

    const list6 = [
        '../images/partner logo/finance loan/13.png',
        '../images/partner logo/finance loan/14.png',
        '../images/partner logo/finance loan/15.png',
    ]
    const list7 = [
        '../images/partner logo/construction and planning/01.png',
        '../images/partner logo/construction and planning/02.png',
        '../images/partner logo/construction and planning/03.png',
        '../images/partner logo/construction and planning/04.png',
        '../images/partner logo/construction and planning/09.png',
        '../images/partner logo/construction and planning/08.png',
        '../images/partner logo/construction and planning/07.png',
    ]


    const Components = ({ title, list }) => {
        return <div className="page-content pt-10 pb-16">
            <h2 style={titleStyle}>{title}</h2>
            <div style={{ 
                backgroundColor: "#fff", 
                width: "100%", 
                display: "flex", 
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "20px",
                padding: "2rem", 
                borderRadius: "40px"
            }}>
                {list && list.map(item => {
                    return <div style={{ 
                        minWidth: "232px", 
                        flex: "1 1 calc(16.666% - 20px)",
                        maxWidth: "calc(16.666% - 20px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <img style={{ 
                                width: '100%', 
                                height: '100%', 
                                maxHeight: "250px", 
                                maxWidth: "250px", 
                                objectFit: "contain",
                                cursor: "pointer"
                            }} src={item.img} alt="" />
                        </a>
                    </div>
                })}
            </div>
        </div>
    }
    const { t, i18n } = useTranslation();

    const dividerStyle = {
        width: "80%",
        margin: "2rem auto",
        border: "none",
        borderTop: "1px solid #ddd",  // 浅灰色的分割线
    }

    return (
        <>
            <SEO title="Our Partners" />
            <Layout>
                <Banner
                    title={t('footerPartners')}
                    bgColor="#F2EDE8"
                    textColor="rgb(41, 55, 102)"
                    description={t('partners')}
                    img={`../images/banner/image.png`}
                />
                <div style={{ backgroundColor: '#fff' }}>
                    {/* <div id="stateGov" className="page-content pt-10 pb-16">
                        <div style={titleStyle}>{t('partnersState')} {t('partnersGovernment')}</div>   
                        <img style={{ backgroundColor: "#fff", borderRadius: "40px", margin: "0 auto", padding: "2rem 3rem", display: "flex", justifyContent: "center", alignItems: "center" }} src='../images/partner logo/federal government/logo_disr.png'></img>
                    </div> */}
                    
                    {/* <hr style={dividerStyle} /> */}

                    <div id="cityCouncil">
                        <Components title={t('partnersCityCouncil')} list={list1}></Components>
                    </div>
{/* 
                    <hr style={dividerStyle} />

                    <div id="saHousing" className="page-content pt-10 pb-16">
                        <h2 style={titleStyle}>{t('partnersSAHousing')}</h2>
                        <div style={{ backgroundColor: "#fff", width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px", gridTemplateRows: "repeat(2, 1fr)", alignItems: "center", padding: "2rem", borderRadius: "40px" }}>
                            {list2 && list2.map(item => {
                                return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "11rem" }}>
                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={item} />
                                </div>
                            })}
                        </div>
                    </div> */}

                    {/* <hr style={dividerStyle} />

                    <div id="realEstate" className="page-content pt-10 pb-16">
                        <h2 style={titleStyle}>{t('partnersOurPartners')}</h2>
                        <h2 style={titleStyle}>{t('partnersRealEstate')}</h2>

                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "2rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list3 && list3.map(item => {
                                return <div style={{ flex: '23%', maxWidth: "20%" }}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div> */}

                    <hr style={dividerStyle} />

                    <div id="dataSupport" className="page-content pt-10 pb-16">
                        <h2 style={titleStyle}>{t('partnersDataSupport')}</h2>

                        <div style={{ backgroundColor: "#fff", width: "50%", margin: "0 auto", display: "flex", justifyContent: "space-around", flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "0rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list4 && list4.map(item => {
                                return <div style={{ flex: '23%', maxWidth: "50%" }}>
                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item.img} alt="" />
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>

                    <hr style={dividerStyle} />

                    <div id="legalSupport" className="page-content pt-10 pb-16">
                        <h2 style={titleStyle}>{t('partnersLegalSupport')}</h2>

                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list5 && list5.map(item => {
                                return <div style={{ flex: '23%', maxWidth: "20%" }}>
                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item.img} alt="" />
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>

                    <hr style={dividerStyle} />

                    {/* <div id="financeLoan" className="page-content pt-10 pb-16">
                        <h2 style={titleStyle}>{t('partnersFinanceLoan')}</h2>

                        <div style={{ backgroundColor: "#fff", width: "70%", margin: "0 auto", display: "flex", justifyContent: "space-around", flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list6 && list6.map(item => {
                                return <div style={{ flex: '23%', maxWidth: "20%" }}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div>

                    <hr style={dividerStyle} /> */}

                    {/* <div className="page-content pt-10 pb-16">
                        <a id="construction"></a>
                        <h2 style={titleStyle}>{t('partnersConstruction')}</h2>

                        <div style={{ backgroundColor: "#fff", width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", rowGap: "4%", padding: "4rem 2rem", borderRadius: "40px", columnGap: "4%" }}>
                            {list7 && list7.map(item => {
                                return <div style={{ flex: '23%', maxWidth: "20%" }}><img style={{ width: '100%', height: '100%', objectFit: "contain", maxHeight: "400px", maxWidth: "400px", margin: "0 auto" }} src={item}></img></div>
                            })}
                        </div>
                    </div> */}
                </div>
            </Layout>
        </>
    );
};

export default OurPartners;
