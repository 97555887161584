import { Link, useNavigate, useLocation } from "react-router-dom";  // 导入 Link 组件
import styles from "./NavBar.module.scss";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const NavDrawer = (props) => {
  const { title, description, btnText, btnLink, linkGroup } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAnchorClick = (e, link) => {
    e.preventDefault();
    
    // 如果不是外部链接
    if (!link.url.startsWith('http')) {
      const isHomePage = link.url.startsWith('/#');
      const targetPath = link.url
      
      const anchor = link.anchor;

      // 如果当前不在目标页面
      if (location.pathname !== targetPath) {
        // 先跳转到目标页面
        navigate(targetPath);
        // 等待页面加载完成后滚动到指定位置
        setTimeout(() => {
          const element = document.getElementById(anchor);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      } else {
        // 如果已经在目标页面，直接滚动
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      // 如果是外部链接，正常跳转
      window.location.href = link.url;
    }
  };

  return (
    <>
      <div className={`${styles["nav-drawer"]}`}>
        <div className={`${styles["nav-drawer-content"]}`}>
          <div className={`${styles["nav-drawer-content-grid"]}`}>
            <div className={`${styles["nav-drawer-content-left"]}`}>
              <p
                className={`${styles["nav-drawer-content-left_title"]} mb-5 lg:text-5xl md:text-3xl text-2xl`}
              >
                {title}
              </p>

              <p className="mb-10 text-gray-600 lg:text-xl md:text-xl">
                {description}
              </p>

              <a
                href={btnLink}
                className={`text-white bg-gray-700 py-3 px-5 rounded inline-block ${styles["a-btn"]} btn-hover`}
              >
                {btnText}
              </a>
            </div>

            <div className={`${styles["nav-drawer-content-right"]}`}>
              {linkGroup
                .sort((a, b) => a.sort - b.sort)
                .map((group, index) => (
                  <div
                    className={styles["nav-drawer-content-right_group"]}
                    key={index}
                  >
                    <p className={styles["nav-drawer-content-right_title"]}>
                      {t(group.title)}
                    </p>
                    <ul className={styles["nav-drawer-content-right_link-ul"]}>
                      {group.list
                        .sort((a, b) => a.sort - b.sort)
                        .map((link, i) => (
                          <li key={i}>
                            {link.anchor ? (
                              <a 
                                href={link.url} 
                                className={styles["nav-link"]}
                                onClick={(e) => handleAnchorClick(e, link)}
                              >
                                {t(link.name)}
                              </a>
                            ) : link.name === "Contact Us" ? (
                              <Link to="/get-in-touch-form" className={styles["nav-link"]}>
                                {t(link.name)}
                              </Link>
                            ) : (
                              <a href={link.url} className={styles["nav-link"]}>
                                {t(link.name)}
                              </a>
                            )}
                            <RightOutlined
                              style={{
                                color: "#0a1e41",
                                fontSize: "0.7rem",
                              }}
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavDrawer;
