import { useState, useRef, useEffect } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import styles from "./HeadCarousel.module.scss";
import { useTranslation } from "react-i18next";

const HeadCarousel = ({ companies }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();

  // const companies = [
  //   {
  //     name: "Cyberate Technologies",
  //     img: "/images/brand/Cyberate.jpg",
  //     link: "https://www.cyberate.com.au/",
  //     description: t('19'),
  //     introduction:
  //      t('cyberateTechnologies'),
  //   },
  //   {
  //     name: "Cyberate Investments",
  //     img: "/images/brand/Investment.jpg",
  //     link: "https://www.cyberate.investments/",
  //     description: t('20'),
  //     introduction:
  //     t('cyberateInvestments'),
  //   },
  //   {
  //     name: "Cyberate Finance",
  //     img: "/images/brand/Finance.jpg",
  //     link: "https://cyberate.finance/",
  //     description: t('developmentLoan'),
  //     introduction:
  //     t('cyberateFinance'),

  //   },
  //   {
  //     name: "Cyberate Project Management",
  //     img: "/images/brand/Project management.jpg",
  //     link: "https://www.cyberate.com.au/",
  //     description: t('22'),
  //     introduction:
  //     t('cyberateProjectManagement'),

  //   },
  //   {
  //     name: "Cyberidge Construction",
  //     img: "/images/brand/Cyberidge.jpg",
  //     link: "https://www.cyberidge.com.au/",
  //     description: t('28'),
  //     introduction:
  //      t('cyberidgeConstruction')
  //   },
  // ];

  const dataListRef = useRef(null);

  // 自动滑动
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === companies?.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 每3秒切换一次

    return () => clearInterval(interval); // 清除定时器
  }, [companies?.length]);

  useEffect(() => {
    const gallery = dataListRef.current;
    if (gallery) {
      const itemWidth = window.innerWidth < 1024 ? window.innerWidth : 400;
      const leftPosition = currentIndex * itemWidth;

      gallery.scrollTo({
        left: leftPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? companies.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === companies.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="relative">
      <div className="flex justify-between items-center container">
        <h2
         style={{fontFamily:'Open Sans'}}
          className="font-['Open Sans'] text-[4rem] font-[500] text-[rgb(41,55,102)]"
        >
          {t('proudGroupMembers')}
        </h2>
        {/* 左右箭头 */}
        <div className={`${styles["btns"]}`}>
          <ArrowLeftOutlined
            className={`${styles["icon"]}`}
            onClick={handlePrev}
          />
          <ArrowRightOutlined
            className={`${styles["icon"]}`}
            onClick={handleNext}
          />
        </div>
      </div>

      {/* 图片滚动列表 */}
      <ul
        ref={dataListRef}
        className={`flex items-stretch overflow-y-hidden overflow-x-scroll  relative lg:static page-content ${styles["carousel-items"]}`}
      >
        {companies && companies.map((company, index) => (
          <li
            className={`flex-none relative lg:pb-8 py-10 lg:px-8 md:px-8 pl-10 ${styles["carousel-item"]}`}
            key={index}
            style={{ color: "rgb(41, 55, 102)" }}
          >
            <div>
              <div className="flex lg:flex-col md:flex-row flex-col">
                <a
                  href={company.link}
                  className={`lg:w-auto md:w-[55%] ${styles["cover"]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {window.screen.width >= 800 ? <div style={{ backgroundColor: "#fff", display: 'flex', alignItems: "center", justifyContent: "center" }} >
                    <img
                      src={company.img}
                      alt={company.name}
                      className="object-contain w-[230px] h-[230px]"
                    />
                  </div> : <img
                    src={company.img}
                    alt={company.name}
                    className="object-contain w-[230px] h-[230px]"
                  />}

                </a>

                <div className=" md:mt-0 overflow-y-hidden md:w-5/12 md:pl-10 lg:w-auto mt-10" style={{ height: "250px" }}>
                  <h3 className="font-lab-antiqua lg:text-3xl md:text-2xl text-xl pr-10 md:pr-0 hover:underline underline-offset-4 decoration-2">
                    <a
                      href={company.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "rgb(41, 55, 102)" }}
                    >
                      {company.name}
                    </a>
                  </h3>
                  <p
                    className={`mt-10 pr-10 md:pr-0 w-full overflow-hidden lg:h-0 lg:text-base text-xs leading-5 text-ellipsis ${styles["description"]}`}
                    style={{ color: "rgb(41, 55, 102)" }}
                  >
                    {company.introduction}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeadCarousel;
