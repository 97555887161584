import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";
import ContactUs from "@/components/contact-us/ContactUs";

const OpenSourceIntelligencePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO title={t('openSourceIntelligence')} />
      <Layout>
        <Banner
          title={t('openSourceIntelligence')}
          bgColor="#F2EDE8"
          textColor="rgb(41, 55, 102)"
          img={'/images/researches/Senior_Analyst_Presenting_Data_Dashboard.jpg'}
        />

        <div className="flex flex-col page-content pt-16 pb-20">
          <div className="flex flex-col md:flex-1 max-w-7xl mx-auto">
            <div className="flex flex-col w-full">
              <h2 className="text-4xl text-center font-bold mb-8" style={{color:"rgb(41, 55, 102)"}}>{t('researchIntro')}</h2>
              <p className="text-lg leading-relaxed mb-12" style={{color:"rgb(41, 55, 102)"}}>
                {t('osintDesc')}
              </p>
            </div>

            <div className="flex flex-col w-full space-y-16">
            <h2 className="text-3xl text-center font-bold" style={{color:"rgb(41, 55, 102)",marginBottom:"-1rem"}}>{t('whyOSINT')}</h2>
              <div className="grid-cols-1 md:grid-cols-3 gap-8" style={{display:"grid"}}>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3" style={{flexShrink:"0"}}>
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                      </svg>
                    </div>
                    <h4 
                      className="text-lg font-semibold"
                      style={{ color: "rgb(41, 55, 102)" }}
                    >
                      {t('osintFeature1Title')}
                    </h4>
                  </div>
                  <p style={{ color: "rgb(41, 55, 102)" }}>
                    {t('osintFeature1Desc')}
                  </p>
                </div>
                
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3"  style={{flexShrink:"0"}}>
                      <svg 
                        className="w-6 h-6 text-green-600" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                        role="img"
                        aria-label={t('osintFeature2Title')}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-900">{t('osintFeature2Title')}</h4>
                  </div>
                  <p className="text-gray-700">{t('osintFeature2Desc')}</p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="flex items-center mb-4">
                    <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-3" style={{flexShrink:"0"}}>
                      <svg 
                        className="w-6 h-6 text-purple-600" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                        role="img"
                        aria-label={t('osintFeature3Title')}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-900">{t('osintFeature3Title')}</h4>
                  </div>
                  <p className="text-gray-700">{t('osintFeature3Desc')}</p>
                </div>
              </div>

              <div className="bg-gray-50 p-8 rounded-xl">
                <h3 
                  className="text-3xl font-bold mb-8 text-center"
                  style={{ color: "rgb(41, 55, 102)" }}
                >
                  {t('osintImplementationTitle')}
                </h3>
                <div className="grid-cols-1 md:grid-cols-2 gap-8" style={{display:"grid"}}>
                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">1</span>
                    </div>
                    <div>
                      <h4 
                        className="text-lg font-semibold mb-2"
                        style={{ color: "rgb(41, 55, 102)" }}
                      >
                        {t('osintStep1Title')}
                      </h4>
                      <p style={{ color: "rgb(41, 55, 102)" }}>
                        {t('osintStep1Desc')}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">2</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2" style={{ color: "rgb(41, 55, 102)" }}>{t('osintStep2Title')}</h4>
                      <p className="text-gray-700">{t('osintStep2Desc')}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">3</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2" style={{ color: "rgb(41, 55, 102)" }}>{t('osintStep3Title')}</h4>
                      <p className="text-gray-700">{t('osintStep3Desc')}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                      <span className="text-blue-600 font-semibold">4</span>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-2" style={{ color: "rgb(41, 55, 102)" }}>{t('osintStep4Title')}</h4>
                      <p className="text-gray-700">{t('osintStep4Desc')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUs />
      </Layout>
    </>
  );
};

export default OpenSourceIntelligencePage; 