import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./Carousel.module.scss";
import { useTranslation } from "react-i18next";
import ImageA from "@/assets/img/Modern_Apartment_Building_with_Glass_Balconies.jpg";
import ImageB from "@/assets/img/Steel_Frame_Building_Under_Construction.jpg";
import ImageC from "@/assets/img/Aerial_View_of_Suburban_Homes.jpg";
import ImageD from "@/assets/img/d.png";

import { ArrowDownOutlined } from "@ant-design/icons";

const Carousel = () => {
  const { t, i18n } = useTranslation();

  const carouselList = [
    {
      sort: 10,
      title: t('propertyDevelopmentClients'),
      img: ImageA,
      description:
        t('9'),
      more: {
        url: "/",
        text: t('readMore'),
      },
    },
    {
      sort: 20,
      title: t('dataDrivenDevelopment'),
      img: ImageB,
      description:
      t('enablingOccupie'),
      more: {
        url: "/",
        text: t('learnMore'),
      },
    },
    {
      sort: 30,
      title: t('10'),
      img: ImageC,
      description:
      t('52'),
      more: {
        url: "/",
        text: t('learnMore'),
      },
    },
  ];

  const carouselListRef = useRef(null);
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const [itemIn, setItemIn] = useState(false);
  const [itemOut, setItemOut] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const navClickChange = useCallback(
    (index) => {
      if (index !== currentNavIndex) {
        setItemOut(true);
        setTimeout(() => {
          setCurrentNavIndex(index);
          setItemOut(false);
          setItemIn(true);
        }, 800);

        setTimeout(() => {
          setItemIn(false);
        }, 1500);
      }
    },
    [currentNavIndex]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      let index = currentNavIndex + 1;
      if (index >= carouselList.length) {
        index = 0;
      }
      navClickChange(index);
    }, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentNavIndex, carouselList.length, navClickChange]);

  useEffect(() => {
    const gallery = carouselListRef.current;
    if (gallery && !isScrolling) {
      gallery.scrollTo({
        left: currentNavIndex * window.innerWidth,
        behavior: "smooth",
      });
    }
  }, [currentNavIndex, isScrolling]);

  const handleScroll = () => {
    const gallery = carouselListRef.current;
    if (gallery) {
      setIsScrolling(true);
      const newIndex = Math.round(gallery.scrollLeft / window.innerWidth);
      setCurrentNavIndex(newIndex);

      clearTimeout(gallery.scrollTimeout);
      gallery.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 100);
    }
  };

  const carouselRef = useRef(null);
  const handleScrollToContnet = () => {
    if (carouselRef.current) {
      const targetDiv = carouselRef.current;
      const targetPosition = targetDiv.offsetTop + targetDiv.offsetHeight;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className={`w-full  ${styles["carousel"]}`} ref={carouselRef} >
        <div className={`${styles["content"]}  relative`}>
          <div
            className={`${styles["content-list"]}`}
            ref={carouselListRef}
            onScroll={handleScroll}
          >
            {carouselList.map((item, index) => (
              <div
                key={`carousel-${index}`}
                className={`${styles["content-item"]} ${
                  currentNavIndex === index ? styles["content-item-active"] : ""
                } ${itemOut ? styles["item-out"] : ""} ${
                  itemIn ? styles["item-in"] : ""
                }`}
              >
                
                {item.sort===10?<div className={`${styles["content-item-div"]}`} style={{float:'right'}}>
                {/* <div className={`${styles["content-media"]}`}>
                    <img src={item.img} alt=""></img>
                  </div> */}
                  <div></div>
                  <div className={`${styles["content-title"]} text-white`} style={{textShadow:'2px 2px 4px #000000',textAlign:"right"}}>
                    {item.title}
                  </div>
                  <div></div>
 
                  <p className={`${styles["content-description"]} text-white`}  style={{textShadow:'2px 2px 4px #000000',textAlign:"right"}}>
                    {item.description}
                  </p>
                  {/* <p>
                    <a
                      href={item.more.url}
                      className={`${styles["content-more-btn"]} text-white btn-hover`}
                    >
                      {item.more.text}
                    </a>
                  </p> */}
                </div>:<div className={`${styles["content-item-div"]}`}>
                  <div className={`${styles["content-title"]} text-white`} style={{textShadow:'2px 2px 4px #000000'}}>
                    {item.title}
                  </div>
                  <div className={`${styles["content-media"]}`}>
                    {/* <img src={item.img} alt=""></img> */}
                  </div>
                  <p className={`${styles["content-description"]} text-white`}  style={{textShadow:'2px 2px 4px #000000'}}>
                    {item.description}
                  </p>
                  {/* <p>
                    <a
                      href={item.more.url}
                      className={`${styles["content-more-btn"]} text-white btn-hover`}
                    >
                      {item.more.text}
                    </a>
                  </p> */}
                </div>}
              </div>
            ))}
          </div>

          <ArrowDownOutlined
            className={`btn-hover ${styles["arrow-btn"]}`}
            style={{
              color: "#38bdf8",
              width:"6rem",
              height:"6rem",
              fontSize:"3rem",
              display: "flex" 
            }}
            onClick={() => handleScrollToContnet()}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#ffffff";
              e.currentTarget.style.backgroundColor = "#38bdf8";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "#38bdf8";
              e.currentTarget.style.backgroundColor = "";
            }}
          />

          <div className={`${styles["nav-ul"]} `}>
            {/* <ul>
              <p
                className={`${styles["tag"]}  `}
                style={{
                  left: currentNavIndex * 1.75 + "rem",
                }}
              ></p>
              {carouselList.map((_, index) => (
                <li
                  key={`carousel-nav-key-${index}`}
                  onClick={() => navClickChange(index)}
                ></li>
              ))}
            </ul> */}
          </div>
        </div>
        <div style={{position: "absolute",backgroundColor:'#000000', top: 0,left: 0,width: "100%",height: "100%",zIndex:  `${currentNavIndex == 0?'-1':'-2'}`,backgroundImage: `url(${carouselList[0].img})`,backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat"}}></div>
        <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%",zIndex:  `${currentNavIndex == 1?'-1':'-2'}`,backgroundImage: `url(${carouselList[1].img})`,backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat"}}></div>
        <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%",zIndex:  `${currentNavIndex == 2?'-1':'-2'}`,backgroundImage: `url(${carouselList[2].img})`,backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat"}}></div>
      </div>
    </>
  );
};

export default Carousel;