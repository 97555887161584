import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import Img01 from "@/assets/img/image01.jpg";
import { useTranslation } from "react-i18next";
import CornerMarkList from "@/components/corner-mark-list/CornerMarkList";
import { Link, useNavigate } from "react-router-dom";
import ContactUs from "@/components/contact-us/ContactUs";

const ScrollToTopLink = ({ to, children, className }) => {
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <Link to={to} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

const BrandStoryDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      number: "01",
      title: t('ourTeam'),
      description: t('ourTeamDesc'),
      link: "/about-us/team"
    },
    {
      number: "02",
      title: t('ourPartners'),
      description: t('ourPartnersDesc'),
      link: "/ourPartners"
    },
    {
      number: "03",
      title: t('ourFleetAndOffice'),
      description: t('ourFleetAndOfficeDesc'),
      link: "/about-us/our-fleet"
    },
    {
      number: "04",
      title: t('career'),
      description: t('careersDesc'),
      link: "/careers"
    }
  ];

  return (
    <>
      <SEO title={t('ourBrand')} />
      <Layout>
        <Banner
          title={t('ourBrand')}
          bgColor="#F2EDE8"
          textColor="rgb(41, 55, 102)"
          img={Img01}
        />
        <div style={{ marginTop: "20px " }} className="page-content ">
          <h2 style={{ 
            padding: "40px 0", 
            fontSize: "2.5rem",
            fontFamily: "'Open Sans', sans-serif",
            color: "rgb(41, 55, 102)",
            fontWeight: 500
          }}>"{t('brandSlogan')}"</h2>
          <p style={{ 
            fontSize: "1.125rem", 
            marginTop: "2rem",
            fontFamily: "'Open Sans', sans-serif",
            color: "rgb(41, 55, 102)"
          }}>{t('brandPositioning')}</p>


          <CornerMarkList></CornerMarkList>


          <div className="flex flex-col md:flex-row items-center gap-8 mb-12">
            <div className="w-full md:w-2/3">
              <img src="../images/brand/OurMission.jpg" alt="Brand Story" className="w-full h-64 md:h-96 object-cover" />
            </div>
            <div className="w-full md:w-1/3" >
              <h2 style={{ paddingBottom: "2rem",fontSize:"2.5rem", fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('footerMission')}</h2>
              <p style={{
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "1.125rem",
                color: "rgb(41, 55, 102)"
              }}>
                {t('brandMission')}
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row items-center gap-8">
            <div className="w-full md:w-1/3">
              <h2 style={{ paddingBottom: "2rem",fontSize:"2.5rem", fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('59')}</h2>
              <p style={{
                fontFamily: "'Open Sans', sans-serif",
                fontSize: "1.125rem",
                color: "rgb(41, 55, 102)"
              }}>
                {t('brandVision')}
              </p>
            </div>
            <div className="w-full md:w-2/3">
              <img src="../images/brand/OurVision.jpg" alt="Our Approach" className="w-full h-64 md:h-96 object-cover" />
            </div>
          </div>

          <div>
            <h2 className="text-center" style={{ padding: "4rem 0 1rem", fontSize: "2.5rem", fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('coreValues')}</h2>
            <div className=" grid-cols-1 md:grid-cols-3 gap-8 justify-center py-8" style={{display:"grid"}}>
              <div className="flex flex-col items-center">
                <img src="../images/brand/img3.png" alt="Core Value 1" className="w-full h-64 md:h-96 object-contain mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('dataCentric')}</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src="../images/brand/img2.png" alt="Core Value 2" className="w-full h-64 md:h-96 object-contain mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('innovativeInsight')}</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src="../images/brand/img1.png" alt="Core Value 3" className="w-full h-64 md:h-96 object-contain mb-4" />
                <h3 className="text-xl font-semibold text-gray-800">{t('holisticSolutions')}</h3>
              </div>
            </div>
          </div>


          <div className="grid grid-cols-1 md:grid-cols-3 gap-8  mt-20">
            {cards.map((card) => (
              <div key={card.number} className="flex flex-col pb-20">
                <div className="mb-4">
                  <span className=" text-lg font-medium border-t-2 border-l-2 border-blue-500 pt-2 pl-2">
                    {card.number}
                  </span>
                </div>
                <h3 className="text-3xl font-bold mb-4" style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>
                  {card.title}
                </h3>
                <p className="mb-6 flex-grow" style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>
                  {card.description}
                </p>
                <ScrollToTopLink
                  to={card.link}
                  className="inline-block border border-gray-900 text-gray-900 px-6 py-2 hover:bg-gray-900 hover:text-white transition-colors duration-200 w-fit no-underline"
                >
                  {t('learnMore')}
                </ScrollToTopLink>
              </div>
            ))}
          </div>

          <div className=" mb-20">
            <h2 style={{ padding: "40px 0", fontSize: "2.5rem", fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('ourStory')}</h2>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('53')}</p>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('65')}</p>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('66')}</p>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('67')}</p>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('68')}</p>
            <p style={{ fontFamily: "'Open Sans', sans-serif", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{t('69')}</p>
          </div>

          
        </div>
        <ContactUs />

      </Layout>
    </>
  );
};

export default BrandStoryDetails;
