import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import ContactUs from "@/components/contact-us/ContactUs";
import Banner from "@/components/banner/Banner";

const OurOfficePage = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <SEO title={t('ourOffice')} />
            <Banner
                title={t('ourOffice')}
                bgColor="#F2EDE8"
                description={''}
                textColor="#0A1E41"
                img={`../images/team/Team.jpg`}
            />
            <div className="page-content">
                {/* <a id="office"></a> */}
                {/* <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
                    {t('54')}
                </h2> */}
            </div>
            <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16" style={{ alignItems: "start" }}>
                <div className="flex-1">
                    <div className="flex flex-col">
                        <p className="text-lg text-gray-700 mb-8">
                            {t('71')}
                        </p>


                    </div>
                </div>

                <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
                    <img
                        src="../images/team/office.png"
                        alt="DDDI Group Illustration"
                        className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
                    />
                </div>





            </div>

            <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16">
                <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
                    <img
                        src="../images/team/office2.png"
                        alt="DDDI Group Illustration"
                        className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
                    />
                </div>

                <div className="flex-1">
                    <div className="flex flex-col">
                        <p className="text-lg text-gray-700 mb-8">
                            {t('72')}

                        </p>


                    </div>
                </div>


            </div>
            <ContactUs />
        </Layout>
    );
};

export default OurOfficePage; 