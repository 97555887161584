import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CardImg from "@/components/card/card-img/CardImg";
import SearchHead from "@/components/search-head/SearchHead";
import ContactUsList from "@/components/contact-us-list/ContactUsList";
import newsList from './news.json';
import Img01 from "@/assets/img/image01.jpg";
import image from "@/assets/img/guide/Large_Eucalyptus_Tree_in_Park.png";
import image2 from "@/assets/img/guide/Koala_in_Eucalyptus_Tree.png";
import image3 from "@/assets/img/guide/Koala_Perched_in_Eucalyptus_Tree.png";
import news2 from "@/assets/img/guide/Mobula_98_sponsership_event.png";
import news3 from "@/assets/img/guide/DSC07158.jpg";

import { useTranslation } from "react-i18next";

import { Pagination, ConfigProvider, Tabs } from "antd";

import ContactUs from "@/components/contact-us/ContactUs";

const PressReleases = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5; // 假设有5页，确保根据实际情况调整
  const { t } = useTranslation();

  // 每5秒自动翻页
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage((prevPage) => {
        // 确保当前页数在1和总页数之间
        return prevPage < totalPages ? prevPage + 1 : 1; // 从第一页循环
      });
    }, 5000);

    // 清理定时器
    return () => clearInterval(interval);
  }, [totalPages]);

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const imageMap = {
    "image": image,
    "image2": image2,
    "image3": image3,
    "news2": news2,
    "news3":news3
  };


  return (
    <>
      <div className="my-0 lg:-mx-8 ">
        <SearchHead></SearchHead>
      </div>

      <div className="page-content">
        <ul className="flex flex-wrap">
          {newsList.map((item, index) => (
            <li className="lg:w-1/3 mb-8 lg:mb-0" key={index}>
              {/* {item.img.url} */}
              <div className="lg:pr-14 lg:pb-14">
                <CardImg
                  img={item.img ? imageMap[item.img.url] : Img01}
                  subTitle={item.subTitle}
                  title={item.title}
                  content={item.content}
                  bigTitle={false}
                  link={`/newsroom/${item.id}`} 
                  linkText={t(item.linkText)}
                ></CardImg>
              </div>
            </li>
          ))}
        </ul>

        <Pagination
          className={`Pagination`}
          align="center"
          current={currentPage}
          onChange={onChangePage}
          defaultCurrent={1}
          total={newsList.length} // 可以保持不变，因为分页控制在当前组件
        />
      </div>
    </>
  );
};

const Contacts = () => {
  return (
    <>
      <div className="page-content">
        <ContactUsList></ContactUsList>
      </div>
    </>
  );
};

const NewsroomPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tabItems] = useState([
    {
      key: "#press-releases",
      label: t('31'),
      children: <PressReleases></PressReleases>,
    },
    // {
    //   key: "#contacts",
    //   label: "CONTACTS",
    //   children: <Contacts></Contacts>,
    // },
  ]);
  const [tabsActiveKey, setTabsActiveKey] = useState("#press-releases");

  const onTabChange = (item) => {
    navigate(item);
    setTabsActiveKey(item);
  };

  const hash = window.location.hash;
  useEffect(() => {
    if (
      hash &&
      tabItems.some((item) => item.key === hash.toLocaleUpperCase())
    ) {
      setTabsActiveKey(hash.toLocaleUpperCase());
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [hash, tabItems]);

  return (
    <>
      <SEO title="Newsroom"></SEO>
      <Layout>
        <Banner
          title={t('newsRoom')}
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        ></Banner>

        <div className="lg:px-14 lg:py-8 md:px-8 px-6 py-4 ">
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  inkBarColor: "#00AEEF",
                  itemSelectedColor: "#000",
                  itemHoverColor: "#000",
                },
              },
            }}
          >
            <Tabs
              activeKey={tabsActiveKey}
              defaultActiveKey={tabsActiveKey}
              items={tabItems}
              onChange={onTabChange}
            ></Tabs>
          </ConfigProvider>
        </div>
        <ContactUs/>
      </Layout>
    </>
  );
};

export default NewsroomPage;
