import React from 'react';

const teamData = [
    { image: 'Jax', name: 'Jax', designation: 'General Manager' },
    { image: 'Willam', name: 'William', designation: 'Director/Data Scientist' },
    { image: 'Eason', name: 'Eason', designation: 'Chief Technology Office' },
    // { image: 'Viv', name: 'Viv', designation: 'Strategy Manager' },
    // { image: 'Ashley', name: 'Ashley', designation: 'Product Manager' },
    // { image: 'Patrick', name: 'Junzhe Diao', designation: 'Technical Manager' },
    { image: 'Lin', name: 'Lin', designation: 'Marketing Manager' },
    // { image: 'Lorenzo', name: 'Xinchao Lin', designation: 'Architect' },
    { image: 'Angelia', name: 'Angelia', designation: 'Admin Assistant' },
    { image: 'Ava', name: 'Ava', designation: 'Administration' },
    // { image: 'Irene', name: 'Irene', designation: 'Statistician' },
    { image: 'Rosy', name: 'Rosy', designation: 'Accountant' },
    // { image: 'Jessie', name: 'Jessie', designation: 'Data Analyst' },
    { image: 'Candice', name: 'Candice', designation: 'ICT Business Analyst' },
    { image: 'Percy', name: 'Percy', designation: 'ICT Business Analyst' },
    // { image: 'Jason', name: 'Jason', designation: 'Software Engineer' },
    // { image: 'Winston', name: 'Winston', designation: 'Software Engineer' },
    // { image: 'Eugenia', name: 'Eugenia', designation: 'Software Engineer' },
    // { image: 'Mingjun', name: 'Mingjun', designation: 'Software Engineer' },
    // { image: 'yuchen', name: 'Yuchen', designation: 'Software Engineer' },
];

const rowsLayout = [2, 3, 4, 3, 4]; // 定义每行的图片数量

const TeamThree = () => {
    let startIndex = 0;

    return (
        <div className="container">
            {rowsLayout.map((itemsPerRow, rowIndex) => {
                const rowItems = teamData.slice(startIndex, startIndex + itemsPerRow);
                startIndex += itemsPerRow;

                return (
                    <div
                        className="row mb-4 justify-content-center"
                        key={`row-${rowIndex}`}
                    >
                        {rowItems.map((member, memberIndex) => (
                            <div
                                className={`col-lg-3 col-md-4 col-sm-12`}
                                key={memberIndex}
                            >
                                <div className="team-card text-center">
                                    <div className="image-container">
                                        <img
                                            src={`/images/team/${member.image}.png`}
                                            alt={member.name}
                                            className="img-fluid mb-3 team-image"
                                        />
                                    </div>
                                    <h3 className="font-weight-bold" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "1.25rem", color: "rgb(41, 55, 102)" }}>{member.name}</h3>
                                    <p style={{ fontFamily: "'Open Sans', sans-serif",fontSize: "1。125rem", color: "rgb(41, 55, 102)", fontWeight: 500 }}>{member.designation}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            })}

            <style jsx>{`
                .image-container {
                    width: 100%;
                    position: relative;
                }

                @media (min-width: 768px) {
                    .image-container {
                        height: 330px;
                        overflow: hidden;
                    }

                    .team-image {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }

                @media (max-width: 767px) {
                    .image-container {
                        height: auto;
                        overflow: visible;
                    }

                    .team-image {
                        width: 100%;
                        height: auto;
                        border-radius: 10px;
                    }
                }
            `}</style>
        </div>
    );
};

export default TeamThree;
