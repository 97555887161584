import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import JoinTheCommunity from "@/components/join-the-community/JoinTheCommunity";
import { useTranslation } from "react-i18next";
import BrandStory from "@/components/about-us/BrandStory";
import Team from "@/components/about-us/Team";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import ContactUs from "@/components/contact-us/ContactUs";
import Separator from "../careers/Separator";
import TeamThree from "../people/Teammember";
const AboutUsPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <SEO title="About Us" />
      <Layout>
        <Banner
          title="About Us"
          bgColor="#F2EDE8"
          description={t('70')}
          textColor="#0A1E41"
          img={`../images/team/Team.jpg`}
        />
        {/* <a id="team"></a> */}
        {/* <div className="page-content">
          <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
            {t('ourTeam')}
          </h2>

        </div> */}

        {/* 关于 ourteam */}
        {/* <Separator />
        <div className="rwt-team-area rn-section-gap">
          <div className="container">

            <TeamThree />
          </div>
        </div>
        <Separator /> */}





        <div className="text-2xl text-gray-500 mb-8 page-content" style={{ margin: '0 auto' }}>
          {/* Our Mission */}
          <a id="mission"></a>
          <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
            {t('10')}
          </h2>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('58')}

          </p>

          {/* Our Vision */}
          <a id="vision"></a>
          <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
            {t('59')}
          </h2>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('60')}

          </p>

          {/* Core Values */}
          <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
            {t('61')}

          </h2>
          <ul className="list-disc pl-6 text-base lg:text-lg text-gray-700 mb-8">

            <li>{t('62')}</li>
            <li>{t('63')}</li>
            <li>{t('64')}</li>
          </ul>

          {/* <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
                  {t('56')}
              </h2> */}
          <a id="story"></a>
          <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
            Brand Story
          </h2>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('53')}
          </p>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('65')}
          </p>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('66')}
          </p>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('67')}
          </p>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('68')}

          </p>
          <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('69')}

          </p>
        </div>



        {/* 车队 */}

        {/* 
        <div style={{ width: "100%" }}>
          <div style={{ backgroundImage: `url('../images/DDDI Group/DSC07058.jpg')`, width: "100%", maxHeight: "100vh", ...backgroundStyle }}>
            <div className="page-content text-2xl text-gray-500 mb-8">
              <a id="ourFleet"></a>
              <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12" style={{ color: "var(--color-footer-bg)", flexShrink: "0" }}>{t('56')}</h2>
              <div style={{ color: "#fff", float: "right", width: "330px", lineHeight: "2rem", fontFamily: "monospace" }}>DDDI Group understands the importance of choosing the right corporate vehicle to enhance our image and operational eﬃciency in today’s business environment. We are proud to select Tesla as our dedicated vehicle.</div>
            </div>
          </div>
          <div style={{ backgroundImage: `url('../images/DDDI Group/DSC07158.jpg')`, width: "100%", maxHeight: "100vh", ...backgroundStyle }}>
            <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", fontFamily: "monospace", lineHeight: '2rem', color: "#fff", padding: '0 4rem' }} className="text-2xl">Tesla oﬀers outstanding performance and advanced electric<br /> technology while embodying our commitment to sustainability.</div>

          </div>
          <div style={{ backgroundImage: `url('../images/DDDI Group/DSC06999.jpg')`, width: "100%", maxHeight: "100vh", ...backgroundStyle}}>
            <div className="page-content text-2xl text-gray-500 mb-8" style={{width:"100%",height:"100%",display:"flex",justifyContent:"flex-end",alignItems:"flex-end"}}>
              <div style={{ width: "380px", float: "right", fontFamily: "monospace", lineHeight: '2rem', color: "#fff",paddingBottom:"200px" }} className="text-2xl">By choosing Tesla, DDDI Group optimises daily operations and showcases our dedication to the future of mobility. Every journey reflects our commitment to innovation and environmental responsibility.</div>

            </div>

          </div>

          
          <div>

          <video
            src="../images/DDDI Group/DDDI Group Tesla 最新.mov"
            className="w-full h-screen object-cover"
            autoPlay
            muted
            playsInline
            loop
          />

          </div>
        </div> */}




        {/* 办公室 */}

        {/* <div className="text-2xl text-gray-500 mb-8 page-content" style={{ margin: '0 auto' }}>

          <a id="office"></a>
          <h2 className="font-lab-antiqua lg:text-5xl text-4xl font-medium text-blue-950 mb-6 mt-12">
            {t('54')}
          </h2>



        </div>
        <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16" style={{alignItems:"start"}}>
          <div className="flex-1">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
                {t('71')}
              </p>


            </div>
          </div>

          <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
            <img
              src="../images/team/office.png"
              alt="DDDI Group Illustration"
              className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
            />
          </div>





        </div>

        <div className="flex flex-col lg:flex-row gap-x-10 page-content pt-10 pb-16">
          <div className="flex-1 flex items-center justify-center mb-10 lg:mb-0">
            <img
              src="../images/team/office2.png"
              alt="DDDI Group Illustration"
              className="w-full max-w-md lg:max-w-lg object-cover rounded-lg shadow-lg"
            />
          </div>

          <div className="flex-1">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
              {t('72')}

              </p>


            </div>
          </div>


        </div> */}






        <div className="text-2xl text-gray-500 mb-8 page-content" style={{ margin: '0 auto', paddingTop: "0" }}>
          {/* <p className="text-base lg:text-lg text-gray-700 mb-8">
            {t('57')}
          </p> */}


          {/* <div className="page-content flex lg:flex-row flex-col justify-between" style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", gap: "20px" }}>
            <div className="text-base lg:text-lg text-gray-700 mb-8" style={{ flex: '1' }}>
              {t('55')}

            </div>
            <div style={{ flex: '1', width: "100%" }}><img src="../images/team/Our office.jpg" ></img></div>

          </div> */}

        </div>



        {/* <p className="text-lg text-gray-700 mb-8">
        Behind every successful property development case lies thorough data analysis and
        verification. DDDI Group has established a solid foundation in the field of real estate
        investment and development. Whether for young first-time homebuyers or experienced
        investors looking to expand their portfolios, DDDI Group provides them with scientific,
        data-driven decision-making support.
      </p>
      <p className="text-lg text-gray-700 mb-8">
        DDDI Group is not just a property development services provider but also a long-term
        partner for its investors. Our business covers property investment, construction, property
        development, and lending financial services. The group represents various market
        segments through its sub-brands, including Cyberate Technologies, Cyberate
        Investments, Cyberate Finance, Cyberate Project Management, Cyberidge
        Construction, and Quant Digital. By extending our services across the entire industry
        chain, DDDI Group effectively integrates resources to provide efficient and precise one-
        stop solutions for property investment and development.
      </p> */}

        <a id="ContactUs"></a>
        <ContactUs />
      </Layout>
    </>
  );
};

export default AboutUsPage;


{/* <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
Our mission
</h2>
<p className="text-lg text-gray-700 mb-8">
We empower property development solutions with data and technology, providing
investors with the most precise and reliable investment insights, enabling them to achieve
sustained wealth growth.
</p>
<h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
Our Vision
</h2>
<p className="text-lg text-gray-700 mb-8">
Our vision is to be the world’s leading property development and investment service
group, driving client success with strategic data and technology solutions.
</p>
<h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
Core Values
</h2>
<ul className="list-disc pl-4 text-lg text-gray-700 mb-8">
<li>Data-Centric</li>
<li>Innovative Insight</li>
<li>Holistic Solutions</li>
</ul> */}
