import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// 导入翻译文件
import translationEN from './pages/en/translation.json';
import translationZH from './pages/cn/translation.json';

// 获取语言设置
const getInitialLanguage = () => {
  // 从本地存储中获取语言设置
  const cachedLang = localStorage.getItem('language');
  if (cachedLang) {
    return cachedLang;
  }
  
  // 如果没有缓存，则使用浏览器语言
  const browserLang = navigator.language.startsWith('zh') ? "zh" : "en";
  // 将选择的语言存入本地存储
  localStorage.setItem('language', browserLang);
  return browserLang;
};

// 初始化 i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      zh: {
        translation: translationZH
      }
    },
    lng: getInitialLanguage(),
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

// 添加语言切换函数
export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
  localStorage.setItem('language', language);
};

export default i18n;
