import CornerMarkItem from "./CornerMarkItem";
import { useTranslation } from "react-i18next";
const CornerMarkList = (props) => {
  const { t } = useTranslation();
  const moduleAList = [
    {
      index: "01",
      title1: t('moduleAList.item1.title1'),
      title2: t('moduleAList.item1.title2'),
      content: t('moduleAList.item1.content'),
      link: "/",
    },
    {
      index: "02",
      title1: t('moduleAList.item2.title1'),
      title2: t('moduleAList.item2.title2'),
      content: t('moduleAList.item2.content'),
      link: "/",
    },
    {
      index: "03",
      title1: t('moduleAList.item3.title1'),
      title2: t('moduleAList.item3.title2'),
      content: t('moduleAList.item3.content'),
      link: "/",
    },
    // {
    //   index: "04",
    //   title: "Invest Efficiently",
    //   content:
    //     "Capture real estate development opportunities that meet your risk/return criteria through a single, centralized source.",
    //   link: "/",
    // },
    // {
    //   index: "05",
    //   title: "Invest Efficiently",
    //   content:
    //     "Capture real estate development opportunities that meet your risk/return criteria through a single, centralized source.",
    //   link: "/",
    // },
  ];

  const { headerText } = props;

  return (
    <>
      {headerText && (
        <h2
          className="font-['Open Sans'] text-[4rem] font-[500] text-[rgb(41,55,102)]"
          style={{fontFamily:'Open Sans'}}
        >
          {headerText}
        </h2>
      )}

      <ul className={`w-full flex flex-wrap mb-14 `}>
        {moduleAList.map((item, index) => (
          <CornerMarkItem
            key={index}
            index={item.index}
            title1={item.title1}
            title2={item.title2}
            content={item.content}
            link={item.link}
          ></CornerMarkItem>
        ))}
      </ul>
    </>
  );
};

export default CornerMarkList;
